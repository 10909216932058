<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="table.dataTable"
      :loading="table.loading"
      :height="
        $vuetify.breakpoint.xlOnly
          ? 650
          : $vuetify.breakpoint.mdAndUp
          ? 450
          : 500
      "
      fixed-header
      hide-default-footer
      disable-pagination
      class="elevation-0"
    >
      <template #item.number="{item}">
        {{ table.dataTable.indexOf(item) + 1 }}
      </template>
      <template #item.action="{ item }">
        <v-btn
          icon
          small
          dark
          class="primary mr-2"
          @click="$router.push(`subjects-detail/${item.lesson}/${idClass}`)"
        >
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import i18n from "@/i18n";
export default {
  props: {
    table: Object,
    idClass: Number
  },
  data() {
    return {
      tableHeaders: [
        { text: "#", value: "number", width: 50 },
        {
          text: i18n.t("app.subjects"),
          value: "lesson_name",
          width: 180
        },
        { text: "Total", value: "count", width: 150 },
        {
          text: i18n.t("app.action"),
          align: "center",
          sortable: false,
          value: "action",
          width: 100
        }
      ]
    };
  }
};
</script>
